'use strict';

var base = require('base/login/login');
var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');

function handlePostUpdate(messageType, message) {
	
	if ($('.update-messages').length === 0) {
		$('body').append(
			'<div class="update-messages"></div>'
		);
	}

	$('.update-messages').append(
		'<div class="alert ' + messageType + ' data-updated text-center" role="alert">'
		+ message
		+ '</div>'
	);

	setTimeout(function () {
		$('.data-updated').remove();
	}, 5000);
}

base.changePasswordForm = function () {
    $('body').on('submit', '.change-password-form',function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                if (!data.success) {
                    form.spinner().stop();
                    formValidation(form, data);
                } else {
                    handlePostUpdate('alert-dark','Password Successfully Reset!');
                    setTimeout(() => {
                        window.location.href = data.redirect;
                        form.spinner().stop();
                      }, "2000");
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
}

base.register = function () {
    $('form.registration').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        var emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\p{Emoji_Modifier_Base}\p{Emoji_Modifier}\u200D\uFE0F]+/u;
        
        var firstname = form.find('#registration-form-fname').val();
        var lastname = form.find('#registration-form-lname').val();
        var emojiError = false;
        
        if (emojiRegex.test(firstname)) {
            form.find('#registration-form-fname').addClass('is-invalid');
            form.find('#form-fname-error').html("First name cannot contain emoji");
            emojiError = true;
        } else {
            form.find('#registration-form-fname').removeClass('is-invalid');
            form.find('#form-fname-error').html("");
        }
        
        if (emojiRegex.test(lastname)) {
            form.find('#registration-form-lname').addClass('is-invalid');
            form.find('#form-lname-error').html("Last name cannot contain emoji");
            emojiError = true;
        } else {
            form.find('#registration-form-lname').removeClass('is-invalid');
            form.find('#form-lname-error').html("");
        }
        
        if (emojiError) {
            return;
        }
        
        form.spinner().start();
        $('form.registration').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    $('form.registration').trigger('login:register:error', data);
                    formValidation(form, data);
                } else {
                    $('form.registration').trigger('login:register:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                }

                form.spinner().stop();
            }
        });
        return false;
    });
};


$(document).ready(function () {
    $('.password-show').on('click', function() {
        if ($(this).closest('form').find('input[type=password]').length > 0){
            $(this).closest('form').find('input[type=password]').attr('type', 'text');
        }else {
            $(this).closest('form').find('input.form-password').attr('type', 'password');
        }
    });

    $('#already-member').on('click', function() {
        $(this).removeClass('active');
        $('#register-tab').removeClass('active');
        $('#register-tab').parent().removeClass('active');
        $('#login-tab').addClass('active');
        $('#login-tab').parent().addClass('active');
    });

    $('.nav-link').on('click', function() {
        $(this).removeClass('active');
        $('.nav-link').removeClass('active');
        $('.nav-link').parent().removeClass('active');
        $(this).addClass('active');
        $(this).parent().addClass('active');
        $('.nav-link').closest('.login-form-nav').find('.tab-pane').removeClass('active');
        $('.nav-link').closest('.login-form-nav').find('.tab-pane[id="' + $(this).attr('aria-controls') + '"]').addClass('active');
    });
});


module.exports = base;
